<template>
  <div id="app">
    <iframe v-if="mapUrl" class="appiframe" ref="iframe" :src="mapUrl" frameborder="0"></iframe>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  computed: {
    showtype() {
      return this.$store.state.showtype
    },
    currentMenu(){
      return this.$store.state.currentMenu
    }
  },
  watch: {
    showtype(newval) {
      console.log(newval);
      let ids = newval.split(",");
      this.ByLayerId = ids;
      this.changeShowtype();
    },
    currentMenu(newval){
      //console.log(newval,`切换标签`);
      let markId;
      if(newval=="home"){//首页党建
        markId = "4_10492";
        this.map3dFly2Mark(markId);
      }else if(newval=="neighbourhood"){//邻里
        markId = "1668753668580_mark";
        this.map3dFly2Mark(markId);
      }else if(newval=="features"){//风貌
        markId = "1681874389708_55";
        this.map3dFly2Mark(markId);
      }else if (newval=="lowcarbon"){//低碳
        markId = "1681874473854_3";
        this.map3dFly2Mark(markId);
      }else if (newval=="wisdom"){//智慧

      }else if (newval=="traffic"){//交通
        markId = "1681721854786_1";
        this.map3dFly2Mark(markId);
      }else if (newval=="industry"){//产业
        //1_95
        markId = "1_95";
        this.map3dFly2Mark(markId);
      }else if (newval=="Culture"){//文化
        markId = "1669255469611_mark";
        this.map3dFly2Mark(markId);
      }else if (newval=="healthy"){//健康
        markId = "1668753503243_mark";
        this.map3dFly2Mark(markId);
      }else if (newval=="government"){//治理
        markId = "1681811065081_30";
        this.map3dFly2Mark(markId);
      }
      //console.log(view);
    },
  },
  data() {
    return {
      mapUrl: "",
      ByLayerId: [],
      viewerLifeCyclePhase: "",
    }
  },
  methods: {
    addEvent() {
      window.addEventListener("message", (event) => {
        let data = event.data;
        if (data.dataType == "entity_click") {
          console.log("appmain中获得的标点数据", data.EntityData)
          this.$store.commit("SET_EntityData", data.EntityData);
        }

        if (data.action == "viewerLifeCyclePhase") {
          if (data.data == 4) {
            this.viewerLifeCyclePhase = 4;
            this.changeShowtype();
          }
        }
      });
    },
    changeShowtype() {
      if (this.viewerLifeCyclePhase != 4) {
        return
      }
      //console.log("changeShowtype");
      let iframe = this.$refs.iframe;
      iframe.contentWindow.postMessage(
        {
          type: "SetVisibleByLayerId",
          layerId: 0,
          visible: false,
        },
        "*"
      );
      //console.log("changeShowtype");
      //console.log(this.ByLayerId);
      this.ByLayerId.forEach((item) => {
        //console.log("bi大屏 SetVisibleByLayerId +++++++++++++++++++++++++++++++++++++++")
        iframe.contentWindow.postMessage(
          {
            type: "SetVisibleByLayerId",
            layerId: item,
            visible: true,
          },
          "*"
        );
      })

    },
    map3dFly2Mark(markId){
    //console.log("map3dFly2Mark");
    let iframe = this.$refs.iframe;
      iframe.contentWindow.postMessage(
        {
          type: "Fly2Mark",
          id: markId,
        },
        "*"
      );
  },
  },
  
  mounted() {
    let url = "https://mapts.cunyougo.com/#/basemap?showLayerId=0&villageId=" + this.$store.state.villageId;
    this.mapUrl = url;
    this.addEvent();
  }
}
</script>

<style lang="scss">
@import "./assets/css/public.scss";

#app {
  background: #00102C;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

:root{
  // --index_height:964px;
  // --login_bg:url('https://file-szzx.cunyougo.com/webapp/official_website/10003/login_bg.png');
  // --home_bg:url('https://file-szzx.cunyougo.com/webapp/official_website/10003/home_bg.png');
  // --bg_index:url('https://file-szzx.cunyougo.com/webapp/official_website/10003/bg_index.png');
  // --industry_bg:url('https://file-szzx.cunyougo.com/webapp/official_website/10003/industry_bg.png');
  // --index-top:url('https://file-szzx.cunyougo.com/webapp/official_website/10003/index-top.png');
}

.appiframe {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100vh;
  width: 100%;
  z-index: 0;
}
</style>
