import Vue from 'vue'
import Router from 'vue-router'
import modules from './modules'
Vue.use(Router)
export const constantRoutes = [
    {
        path: '/404',
        component: () =>
            import('@/views/404.vue'),
            redirect: '/home/index',    
        hidden: true
    },
    {
        path: '*',
        redirect: '/home/index',
        hidden: true
    },
    ...modules
]
const createRouter = () => new Router({
    mode: 'hash', // require service support
    scrollBehavior: () => ({
        y: 0
    }),
    routes: constantRoutes
})
const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}
export default router

