import Layout from '@/layout'
const modules = [{
    name: 'home',
    path: '/home',
    component: Layout,
    hidden: true,
    redirect: '/home/index',
    children: [
        {
            path: 'index',
            name: 'home',
            component: () =>
                import('@/views/home/index'),
            meta: {
                title: '首页',
            }
        },
    ]
},{
    name: 'traffic',
    path: '/traffic',
    component: Layout,
    hidden: true,
    redirect: '/traffic/index',
    children: [
        {
            path: 'index',
            name: 'traffic',
            component: () =>
                import('@/views/traffic/index'),
            meta: {
                title: '交通',
            }
        },
    ]
},{
    name: 'industry',
    path: '/industry',
    component: Layout,
    hidden: true,
    redirect: '/industry/index',
    children: [
        {
            path: 'index',
            name: 'industry',
            component: () =>
                import('@/views/industry/index'),
            meta: {
                title: '产业',
            }
        },
{
  name: 'neighbourhood',
  path: '/neighbourhood',
  component: Layout,
  hidden: true,
  children: [
      {
          path: 'index',
          name: 'neighbourhood',
          component: () =>
              import('@/views/neighbourhood/index'),
          meta: {
              title: '邻里',
          }
      },
  ]
},
{
  name: 'features',
  path: '/features',
  component: Layout,
  hidden: true,
  children: [
      {
          path: 'index',
          name: 'features',
          component: () =>
              import('@/views/features/index'),
          meta: {
              title: '风貌',
          }
      },
  ]
},
{
  name: 'lowcarbon',
  path: '/lowcarbon',
  component: Layout,
  hidden: true,
  children: [
      {
          path: 'index',
          name: 'lowcarbon',
          component: () =>
              import('@/views/lowcarbon/index'),
          meta: {
              title: '低碳',
          }
      },
  ]
},
{
  name: 'wisdom',
  path: '/wisdom',
  component: Layout,
  hidden: true,
  children: [
      {
          path: 'index',
          name: 'wisdom',
          component: () =>
              import('@/views/wisdom/index'),
          meta: {
              title: '智慧',
          }
      },
  ]
},

]
},{
    name: 'healthy',
    path: '/healthy',
    component: Layout,
    hidden: true,
    redirect: '/healthy/index',
    children: [
        {
            path: 'index',
            name: 'healthy',
            component: () =>
                import('@/views/healthy/index'),
            meta: {
                title: '健康',
            }
        },
    ]
},
{
    name: 'Culture',
    path: '/Culture',
    component: Layout,
    hidden: true,
    redirect: '/Culture/index',
    children: [
        {
            path: 'index',
            name: 'Culture',
            component: () =>
                import('@/views/Culture/index'),
            meta: {
                title: '文化',
            }
        },
    ]
},
{
    name: 'government',
    path: '/government',
    component: Layout,
    hidden: true,
    redirect: '/government/index',
    children: [
        {
            path: 'index',
            name: 'government',
            component: () =>
                import('@/views/government/index'),
            meta: {
                title: '治理',
            }
        },
    ]
},
]
export default modules