import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    currentMenu: 'home',//导航的高亮
    TOPtime: "", //头部时间
    EntityData: {}, //标注信息
    showtype: "", //地图标注展示类型
    villageId: 279, //村id
    entityUid:"", //标注信息_ID
    businessEntityId:"",//标注业态ID
  },
  mutations: {
    changeStatus(state, status) { //  重复赋值
      state.tableStatus = status;
    },
    SET_CURRENT_MENU(state, currentMenu) {
      state.currentMenu = currentMenu;
    },
    SET_Time(state) {
      state.TOPtime = new Date();
    },
    //地图相关
    SET_EntityData(state, status) {
      state.EntityData = status;
      state.businessEntityId=status.businessEntityId;
      state.entityUid=status.entityUid;
    },
    SET_showtype(state, status) {
      state.showtype = status;
    },
  },
})
export default store