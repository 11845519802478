<template>
  <div class="app-wrapper">
      <Topbar />
      <AppMain />
  </div>
</template>

<script>
import Topbar from './components/Topbar.vue'
import AppMain from './components/AppMain.vue'
export default {
  name: 'Layout',
  components: {
      Topbar,
      AppMain
  }
}
</script>

<style lang="scss" scoped>
</style>
