import Vue from 'vue'
import App from './App.vue'
import store from './store/index'

import ElementUI from 'element-ui'
Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css'
Vue.config.productionTip = false
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts
import router from './router/index'
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
    store.commit('SET_CURRENT_MENU', to.name)
    store.commit('SET_Time')
  }
  next();

});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
