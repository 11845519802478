<template>
   <div class="top">
        <div class="title">泰顺岭北综合数据平台</div>
        <div class="Topbar">
            <div v-for="(item,index) of arrTitle" class="bar pointer" :key="index" :class="act_class(item.Menu,currentMenu)">
                <div @click="gorurl(item.path)">{{item.name}}</div>
            </div>
        </div>
        <div >
             <div class="time">{{time_time}}</div>
             <div class="date">{{time_day}}</div>
        </div>
   </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'Topbar',
  data(){
      return{
          arrTitle:[{
              path:'/home/index',
              name:'党建统领',
              index:0,
              Menu:['home']
          },{
              path:'/neighbourhood/index',
              name:'邻里',
              index:1,
              Menu:['neighbourhood']
          },{
              path:'/features/index',
              name:'风貌',
              index:2,
              Menu:['features']
          },{
              path:'/lowcarbon/index',
              name:'低碳',
              index:3,
              Menu:['lowcarbon']
          },{
              path:'/wisdom/index',
              name:'智慧',
              index:4,
              Menu:['wisdom']
          },{
              path:'/traffic/index',
              name:'交通',
              index:5,
              Menu:['traffic']
          },{
              path:'/industry/index',
              name:'产业',
              index:6,
              Menu:['industry']
          },{
              path:'/Culture/index',
              name:'文化',
              index:7,
              Menu:['Culture']
          },{
              path:'/healthy/index',
              name:'健康',
              index:8,
              Menu:['healthy']
          },{
              path:'/government/index',
              name:'治理',
              index:9,
              Menu:['government']
          },],
          time_day:"",
          time_time:"",
      }
  },
  computed: {
    ...mapState(['currentMenu']),
    name(){
       return localStorage.getItem('guanwangname')
    }
  },
  watch:{

  },
  components: {

  },
  methods: {
   gorurl(path){
      this.$store.commit("SET_showtype", "-1");
      this.$router.push(path)
   },
   act_class(arr,str){
       let end = arr.find((val)=>{
           return val == str
       })
       return end?'barselect':''
   },
    settime(){
        let date="",m,d,h,minute,second
        setInterval(()=>{
            date=new Date()
            var y = date.getFullYear()
                m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                h = date.getHours()
                h = h < 10 ? ('0' + h) : h
                minute = date.getMinutes()
                second = date.getSeconds()
                minute = minute < 10 ? ('0' + minute) : minute
                second = second < 10 ? ('0' + second) : second
            this.time_day=`${y}.${m}.${d}`
            this.time_time=`${h}.${minute}.${second}`
        },1000)
    }
  },
  mounted(){ 
    this.settime()
  },
}
</script>
<style lang="scss" scoped>
.top{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: url('../../../public/static/img/topback.png'); 
    width: 100%;
    background-size: 100% 100%;
    height: 5rem;
    display: flex;
    justify-content: space-around;
    .pointer{
        cursor: pointer;
    }
    .title{
        margin-top: 0.8rem;
        margin-left: 4rem;
        width: 17rem;
        font-size: 27px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 38px
    }
    .Topbar{
        margin-top: 1rem;
        display: flex;
        height: 100%;
        margin-left: 10px;
        .bar{
            width: 10rem;
            text-align: center;
            cursor: pointer;
            height: 40px;
            background: url('../../../public/static/img/topbar.png'); 
            background-size: 100% 100%;
            font-size: 18px;
            font-weight: 400;
            color: #BEEEFF;
            line-height: 33px;
            margin-left: -32px;
            text-shadow: 0px 0px 1px #00102C;
            cursor: pointer;
        }
        .barselect{
            background: url('../../../public/static/img/topbarselect.png'); 
             background-size: 100% 100%;
        }
    }
    .time{
        margin-top: 0.6rem;
        width: 72px;
        margin-left: 5px;
        height: 25px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 25px;
    }
    .date{
        width: 85px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 20px;
    }
}


</style>